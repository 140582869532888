import breakpoints from "@vertikal/e-prospera.styles.breakpoints";
import colors from "@vertikal/e-prospera.styles.colors";
import Button from "@vertikal/e-prospera.ui.button";
import Icon from "@vertikal/e-prospera.ui.icon";
import Text from "@vertikal/e-prospera.ui.text";
import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";

const BecomePartnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${colors.success.c200};
  padding: 2rem;
  border-radius: 0.5rem;
  justify-content: center;
  box-sizing: border-box;
  z-index: 1;
  @media ${breakpoints.lg} {
    max-width: 23rem;
  }
  ${(props) => {
    if (props.$hideSmall) {
      return `@media ${breakpoints.lgDown} {
        display: none !important;
      };`;
    }
    if (props.$hideBig) {
      return `@media ${breakpoints.lg} {
        display: none !important;
      };`;
    }
    return;
  }}
`;
const BecomePartnerIcon = styled(Icon)`
  margin-bottom: 0.5rem;
  color: ${colors.accent.c600};
  font-size: 4rem;
  margin-top: 1rem;
  align-self: center;
`;

export const BecomePartner = ({ hideBig, hideSmall, id }) => {
  return (
    <BecomePartnerWrapper $hideBig={hideBig} $hideSmall={hideSmall} id={id}>
      <BecomePartnerIcon name="local_mall" />
      <Text variant="h5">
        <Trans>public.marketplace.partner.header</Trans>
      </Text>
      <Text>
        <Trans>public.marketplace.partner.text</Trans>
      </Text>
      <Button
        as="a"
        target="_blank"
        href="https://prospera.hn/contact"
        color="accent"
        id={`${id}-apply-now`}
      >
        <Trans>dashboard.apply_now</Trans>
      </Button>
    </BecomePartnerWrapper>
  );
};

export default BecomePartner;
