import { Angles } from "@vertikal/e-prospera.ui.angles";
import Box from "@vertikal/e-prospera.ui.box";
import Text from "@vertikal/e-prospera.ui.text";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import BecomePartner from "../components/BecomePartner/BecomePartner";
import { Breadcrumbs } from "../components/Breadcrumbs/Breadcrumbs";
import GlobalSettings from "../components/GlobalSettings";
import MainMenu from "../components/MainMenu/MainMenu";
import {
  MarketplaceGrid,
  MarketplaceHead,
  MarketplaceIntro,
  MarketplaceIntroText,
  ServiceProviderCard,
  ServiceProviderImage,
  ServiceProviders,
} from "../styles/marketplace.styles";

const MarketPlacePage = () => {
  const [t] = useTranslation();

  const pageTitle = t("public.marketplace.pageTitle");
  const metaTitle = t("public.marketplace.pageMetaTitle");
  const metaDescription = t("public.marketplace.intro_1");

  const marketPlaceItems = [
    {
      id: "marketplace-service-employment",
      title: <Trans>common.employment</Trans>,
      items: [
        {
          image: (
            <StaticImage
              style={{
                maxHeight: "7rem",
                margin: "auto",
              }}
              src="../images/pes_logo.png"
              alt="PES logo"
            />
          ),
          title: <Trans>PES.prospera_employment_solutions</Trans>,
          id: "marketplace-pes",
          href: "prospera-employment-solutions",
        },
        {
          image: (
            <StaticImage
              style={{
                maxHeight: "7rem",
                margin: "auto",
              }}
              src="../images/relay-logo.png"
              alt="Relay logo"
            />
          ),
          title: <Trans>marketplace.labor_compliance_payroll_tool</Trans>,
          id: "marketplace-relay",
          href: "relay-human-cloud",
        },
        {
          image: (
            <StaticImage
              style={{
                maxHeight: "7rem",
                margin: "auto",
              }}
              src="../images/palma-logo.svg"
              alt="Palma logo"
            />
          ),
          title: <Trans>marketplace.labor_compliance_payroll_tool</Trans>,
          id: "marketplace-palma",
          href: "palma",
        },
      ],
    },
    {
      id: "marketplace-service-insurance",
      title: <Trans>common.insurance</Trans>,
      items: [
        {
          image: (
            <StaticImage
              style={{
                maxHeight: "7rem",
                margin: "auto",
              }}
              src="../images/pic_logo.png"
              alt="PIC logo"
            />
          ),
          title: <Trans>insured_nomads.insurance_agent</Trans>,
          id: "marketplace-pic",
          href: "prospera-insurance-company",
        },
        {
          image: (
            <StaticImage
              style={{
                maxHeight: "7rem",
                margin: "auto",
              }}
              src="../images/insurednomads_logo.svg"
              alt="Insured Nomads logo"
            />
          ),
          title: <Trans>insured_nomads.insurance_agent</Trans>,
          id: "marketplace-insured-nomads",
          href: "insured-nomads",
        },
      ],
    },
    {
      id: "marketplace-service-banking",
      title: <Trans>common.banking_and_fintech</Trans>,
      items: [
        {
          image: (
            <StaticImage
              style={{
                maxHeight: "7rem",
                margin: "auto",
              }}
              src="../images/payoneer_dark_logo.svg"
              alt="Payoneer logo"
            />
          ),
          title: <Trans>payoneer.payment_account_provider</Trans>,
          id: "marketplace-payoneer",
          href: "payoneer",
        },
        {
          image: (
            <StaticImage
              style={{
                maxHeight: "7rem",
                margin: "auto",
              }}
              src="../images/open-node-logo.svg"
              alt="Open Node logo"
            />
          ),
          title: "Open Node",
          id: "marketplace-open-node",
          href: "open-node",
        },
        {
          image: (
            <StaticImage
              style={{
                maxHeight: "7rem",
                margin: "auto",
              }}
              src="../images/request-finance-logo.svg"
              alt="Request Finance logo"
            />
          ),
          title: "Request Finance",
          id: "marketplace-request-finance",
          href: "request-finance",
        },
        {
          image: (
            <StaticImage
              style={{
                maxHeight: "7rem",
                margin: "auto",
              }}
              src="../images/candypay-logo.svg"
              alt="Candypay logo"
            />
          ),
          title: "CandyPay",
          id: "marketplace-candypay",
          href: "candypay",
        },
        {
          image: (
            <StaticImage
              style={{
                maxHeight: "7rem",
                maxWidth: "88.15px",
                margin: "auto",
              }}
              src="../images/coinable-logo.svg"
              alt="Coinable logo"
            />
          ),
          title: "Coinable",
          id: "marketplace-coinable",
          href: "coinable",
        },
        {
          image: (
            <StaticImage
              style={{
                maxHeight: "7rem",
                maxWidth: "225px",
                margin: "auto",
              }}
              src="../images/reap-technologies-logo.svg"
              alt="Reap Technologies logo"
            />
          ),
          title: "Reap Technologies",
          id: "marketplace-reap-technologies",
          href: "reap-technologies",
        },
        {
          image: (
            <StaticImage
              style={{
                maxHeight: "7rem",
                maxWidth: "48px",
                margin: "auto",
              }}
              src="../images/amity-age-academy-logo.svg"
              alt="Amity Age Academy logo"
            />
          ),
          title: "Amity Age Academy",
          id: "marketplace-amity-age-academy",
          href: "amity-age-academy",
        },
        {
          image: (
            <StaticImage
              style={{
                maxHeight: "7rem",
                margin: "auto",
              }}
              src="../images/sphere-logo.svg"
              alt="Sphere logo"
            />
          ),
          title: "Sphere",
          id: "marketplace-sphere",
          href: "sphere",
        },
      ],
    },
    {
      title: "Arbitration",
      id: "marketplace-service-arbitration",
      items: [
        {
          image: (
            <StaticImage
              style={{
                maxHeight: "7rem",

                margin: "auto",
              }}
              src="../images/prospera-arbitration-center-logo.png"
              alt="Prospera Arbitration logo"
            />
          ),
          title: <Trans>common.arbitration</Trans>,
          id: "marketplace-prospera-arbitration",
          href: "prospera-arbitration-center",
        },
      ],
    },
    {
      id: "marketplace-service-real-estate",
      title: "Real estate",
      items: [
        {
          image: (
            <StaticImage
              style={{
                maxHeight: "7rem",
                margin: "auto",
              }}
              src="../images/duna-logo.png"
              alt="Duna logo"
            />
          ),
          title: <Trans>marketplace.retail_and_residential_development</Trans>,
          id: "marketplace-duna",
          href: "duna-residences",
        },
        {
          image: (
            <StaticImage
              style={{
                maxHeight: "7rem",
                maxWidth: "5.65rem",
                margin: "auto",
              }}
              src="../images/pristine-heights.png"
              alt="Pristine Heights logo"
            />
          ),
          title: <Trans>marketplace.retail_and_residential_development</Trans>,
          id: "marketplace-pristine-heights",
          href: "pristine-heights",
        },
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>,
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
      </Helmet>
      <GlobalSettings />
      <MainMenu>
        <MarketplaceHead>
          <MarketplaceIntro>
            <Breadcrumbs>
              <Link to="/">
                <Trans>common.home</Trans>
              </Link>
              <span>
                <Trans>common.marketplace</Trans>
              </span>
            </Breadcrumbs>
            <Box
              display="flex"
              justifyContent="space-between"
              style={{ gap: "2rem" }}
            >
              <MarketplaceIntroText>
                <Text variant="h2" as="h1">
                  <Trans>common.marketplace</Trans>
                </Text>
                <Text variant="p-sm">
                  <Trans>public.marketplace.intro_1</Trans>
                </Text>
                <Text variant="p-sm">
                  <Trans>public.marketplace.intro_2</Trans>
                </Text>
              </MarketplaceIntroText>
              <BecomePartner id="becomePartner-top" hideSmall />
            </Box>
          </MarketplaceIntro>
          <Box marginBottom={6.5}>
            <Angles />
          </Box>
        </MarketplaceHead>
        <MarketplaceGrid>
          {marketPlaceItems.map((category) => {
            return (
              <React.Fragment key={category.id}>
                <Box>
                  <Text id={category.id} color="gray">
                    {category.title}
                  </Text>
                </Box>
                <ServiceProviders>
                  {category.items.map((item) => {
                    return (
                      <Link
                        key={item.id}
                        id={item.id}
                        style={{ textDecoration: "none" }}
                        to={item.href}
                      >
                        <ServiceProviderCard
                          elevation={3}
                          borderRadius={6}
                          key={item.id}
                          interactive
                        >
                          <ServiceProviderImage>
                            {item.image}
                          </ServiceProviderImage>
                          <Text variant="p-sm" color="gray">
                            {item.title}
                          </Text>
                        </ServiceProviderCard>
                      </Link>
                    );
                  })}
                </ServiceProviders>
              </React.Fragment>
            );
          })}
          <ServiceProviders>
            <BecomePartner id="becomePartner-bottom" hideBig />
          </ServiceProviders>
        </MarketplaceGrid>
      </MainMenu>
    </>
  );
};

export default MarketPlacePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
