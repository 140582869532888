import breakpoints from "@vertikal/e-prospera.styles.breakpoints";
import colors, { pureColors } from "@vertikal/e-prospera.styles.colors";
import Paper from "@vertikal/e-prospera.ui.paper";
import styled from "styled-components";

export const MarketplaceHead = styled.div`
  background-color: ${colors.accent.c900};
`;

export const MarketplaceIntro = styled.div`
  padding: 1rem;
  max-width: 100rem;
  margin: 0 auto;
  gap: 4.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 2.5rem;
  @media ${breakpoints.md} {
    padding-left: 5.9375rem;
    padding-right: 5.9375rem;
  }
  @media ${breakpoints.lg} {
    margin-bottom: -10rem;
  }
`;

export const MarketplaceIntroText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: ${pureColors.white};
  max-width: 36rem;
  p {
    color: ${colors.gray.c300};
  }
`;

export const ServiceProviders = styled.div`
  display: grid;
  width: 100%;
  gap: 2rem;
  margin-bottom: 1.5rem;
  grid-template-columns: 1fr;
  @media ${breakpoints.sm} {
    grid-template-columns: repeat(auto-fill, minmax(23rem, 1fr));
  }
`;

export const ServiceProviderCard = styled(Paper)`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 11.25rem;
  box-sizing: border-box;
`;

export const MarketplaceGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5rem;
  padding: 1rem;
  max-width: 100rem;
  margin: 0 auto;
  @media ${breakpoints.md} {
    padding: 5.9375rem;
  }
`;

export const ServiceProviderImage = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 14rem;
`;
